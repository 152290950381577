import React, { Component } from 'react';

class RoomInformation extends Component {
    state = {  }
    render() { 
        return (
        <section className="section padding-vertical-small">
            <div className="container padding-vertical-small">
                <div className="section padding-vertical-small">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <h2 className="margin-bottom-zero">Rooms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3 is-offset-1">
                            <div className="content">
                                <p>The Italian Forum</p>
                                <p>Suite 45, 21-23 Norton St</p>
                                <p>Leichhardt, NSW, 2040</p>
                                <br />                                
                                <p><span className='has-text-weight-bold'>Phone: </span><a href='tel:1300866630'> 1300 866 630</a></p>
                                <p><span className='has-text-weight-bold'>Email: </span><a href="mailto:info@drpierataylor.com.au">info@drpierataylor.com.au</a></p>
                            </div>
                        </div>
                        <div className="column is-7">
                            <div className='content'>
                            <iframe style={{height:'12rem', width:'100%', border:'0'}} frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=Suite%2045%2C%20the%20Italian%20forum%2C%20Leichhardt%20&key=AIzaSyA8oYMWJhABpa93xkVL7K9xRUgx4trS-Q8" allowFullScreen />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}
 
export default RoomInformation;