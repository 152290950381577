import React from 'react'
import Helmet from 'react-helmet'
import Affiliations from '../AffiliationsBar'
import RoomInformation from '../RoomInformation'

const HomePageTemplate = () => (
  <div>
    <Helmet>
      <title>Dr. Piera Taylor</title>
      <meta name='keywords' content='surgeon, ENT, paediatric, Sydney, doctor, Piera Taylor, 
      cleft palate, hearing issues, salivary management, grommets'/>
      <meta name='description' content="Dr. Piera Taylor, one of Sydney's leading Paediatric ENT Surgeons, specialises in salivary management, cleft palate and ENT conditions for children with diabilities and syndromes."/>
      <meta name='robots' content='index,follow'/>
    </Helmet>
    <section className='hero is-primary is-small'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h1 className='title'>
                  Dr. Piera Taylor, Paediatric ENT Surgeon
                </h1>
                <h2 className="subtitle is-italic">
                  MBBS, BDS, FRACS (Otolaryngology, Head and Neck Surgery)
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section section--gradient padding-vertical-small'>
      <div className='container padding-vertical-small'>
        <div className='section padding-vertical-small'>
          <div className='columns'>
            <div className='column is-5 is-offset-1'>
              <div className='content'>
                <div>
                  <p>Dr. Piera Taylor is a Specialist Paediatric Ear Nose and Throat Surgeon. She completed her advanced training in ENT and Head and Neck Surgery in Sydney, Australia, before receiving her specialist qualifications from The Royal Australian College of Surgeons. Dr. Taylor spent a further year training in Paediatric ENT Surgery at The Children’s Hospital at Westmead, an area in which she has a special interest.</p>
                  <p>Dr. Taylor is dually qualified in both Medicine and Dentistry. She obtained both her undergraduate degrees at the University of Sydney and was awarded Honours and the University Medal for each.</p>
                  <p>Dr. Taylor is now a consultant Specialist Ear Nose and Throat surgeon at the The Children’s Hospital at Westmead. She has special interest in ear disease and ENT conditions in children with cleft palate. She also runs a Salivary, Aspiration and Drooling clinic focussing on the surgical management of these issues.</p>
                  <p>Dr. Taylor runs a private practice in Leichhardt, Sydney, where she sees children of all ages from birth to adolescence. She operates at the Children's Hospital Westmead, Hunters Hill Private Hospital and Canterbury Public Hospital.</p>
                </div>
              </div>
            </div>
            <div className="column is-4 is-offset-1">
              <div className='content'>
                <p className='has-text-centered'>
                  <img alt='Dr Piera Taylor, paediatric ENT surgeon in Sydney' src='/img/portrait.jpg' />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Affiliations /> 
    <br />
    <RoomInformation />
  </div>
)

export default HomePageTemplate
