import React from 'react'
import PropTypes from 'prop-types'

const Affiliations = () => (
  <section className ='section padding-vertical-zero'>
    <div className='columns margin-bottom-zero'>
      <div className = 'column is-10 is-offset-1 padding-vertical-zero margin-bottom-zero margin-top-zero'>
        <hr className ='dark-hr'/>
      </div>
    </div>
    <div className='columns is-vcentered padding-vertical-zero margin-bottom-zero margin-top-zero'>
      <div className='column is-2 is-offset-1'>
        <div className='content'>
          <p className='has-text-centered'>
            <a href = 'https://www.surgeons.org/en' target='_blank' ><img alt ='FRACS logo, button to access website' src ='/img/FRACS-logo.png' className='affiliation-badge'/></a>
          </p>
        </div>
      </div>
      <div className='column is-2'>
        <div className='content'>
          <p className='has-text-centered'>
            <a href = 'http://www.asporl.org/' target='_blank'><img alt='ANZSPO logo, button to access website' src='/img/ANZSPO-logo.jpeg' className='affiliation-badge'/></a>
          </p>
        </div>
      </div>
      <div className='column is-2'>
        <div className='content'>
          <p className='has-text-centered'>
            <a href='http://www.asohns.org.au/' target='_blank'><img alt='ASOHNS logo, button to access website' src='/img/ASOHNS-logo.png' className='affiliation-badge'/></a>
          </p>
        </div>
      </div>
      <div className='column is-2'>
        <div className='content'>
          <p className='has-text-centered'>
            <a href='https://www.schn.health.nsw.gov.au/hospitals/chw' target='_blank'><img alt = 'Westmead Childrens Hospital logo, button to access website' src = '/img/WESTMEAD-logo.jpg' className='affiliation-badge'/></a>
          </p>
        </div>
      </div>
      <div className='column is-2'>
        <div className='content'>
          <p className='has-text-centered'>
            <a href='https://www.sydney.edu.au/medicine-health/schools/sydney-medical-school.html' target='_blank'><img alt = 'University of Sydney logo, button to access website' src = '/img/USYD-logo.png' className='affiliation-badge'/></a>
          </p>
        </div>
      </div>
    </div>
    <div className='columns'>
      <div className = 'column is-10 is-offset-1 padding-vertical-zero margin-bottom-zero margin-top-zero'>
        <hr className ='dark-hr'/>
      </div>
    </div>
  </section> 
)


export default Affiliations
